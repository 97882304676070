<template>
	<div class="index _flex-row-center _pc-pad-top-60 _mb-pad-top-115">
		<div class="login-box _pc-mar-top-10 _mb-pad-x-60">
			<div class="title _pc-font-36 _b _mb-font-48 _pc-mar-bottom-40 _mb-mar-bottom-50">
				{{ $t('欢迎登录') }}
			</div>
			<!-- 邮箱 -->
			<div class="li _pc-mar-bottom-35 _mb-mar-bottom-40">
				<div class="li-hd _flex-row-mid">
					<img class="_pc-mar-right-10 img-icon _mb-mar-right-15" src="~@/assets/image/hangda/logo/mail.png" alt="" />
					<div class="_pc-font-20 _mb-font-28" style="color: #333333;">{{ $t('电子邮箱') }}</div>
				</div>
				<div class="input-box _pc-w-max _pc-h-60 _mb-h-90 _white-bg _flex-row-mid _pc-pad-x-25 _mb-pad-x-30">
					<input type="text" style="width: 100%;" v-model.trim="email" :placeholder="$t('请输入')">
				</div>
			</div>
			<!-- 密码 -->
			<div class="li  _pc-mar-bottom-30 _mb-mar-bottom-30">
				<div class="li-hd _flex-row-mid">
					<img class="_pc-mar-right-10 img-icon _mb-mar-right-15"  src="~@/assets/image/hangda/logo/password.png" alt="" />
					<div class="_pc-font-20 _mb-font-28" style="color: #333333;">{{ $t('密码') }}</div>
				</div>
				<div class="input-box _pc-w-max _pc-h-60 _mb-h-90 _white-bg _flex-row-mid _pc-pad-x-25 _mb-pad-x-30">
					<input type="password" style="width: 100%;" v-model.trim="pwd" :placeholder="$t('请输入')">
				</div>
			</div>
			<!-- 记住密码 -->
			<div class="remember-password _pc-mar-bottom-40 _flex-row-mid _mb-mar-bottom-50">
				<input class="_pc-mar-right-10 checkbox-ico _mb-mar-right-10" style="" type="checkbox" name="" id="remember-password" :value="IsRemenber" :checked="IsRemenber" @click="rememberPwd" />
				<label for="remember-password" class=" _pc-font-18 _mb-font-28">{{ $t('记住密码') }}</label>
			</div>
			<!-- 登录 -->
			<input type="button" :class="{'login-button': pwd && email}" class="login-button2" @click="login()" :value="$t('登录')" :disabled="!pwd || !email">
			<div class="logio-box-footer _pc-mar-top-30 _mb-mar-top-25 _flex-row-ard-mid _pc-w-max _pc-pad-x-40 _mb-pad-x-0" style="color: #999;">
				<router-link class="left btn-route _mb-flex-row-center-mid _mb-mar-right-35" :to="'/register'">{{ $t('注册') }}</router-link>
				<span class="_mb-display-none">|</span>
				<router-link class="right btn-route _mb-flex-row-center-mid" :to="'/find_pwd'">{{ $t('找回密码') }}</router-link>
			</div>
		</div>
		<!-- footer -->
		<div class="_pc-display-none" style="position: absolute;bottom: 0;left: 0;right: 0;">
			<FooterVueTwo></FooterVueTwo>
		</div>
	</div>
</template>

<script>
	import { submitLogin } from '@/api/jiyun';
	import { setCookies, getCookies } from "@/libs/util";
	import FooterVueTwo from '../../components/FooterTwo.vue';
 	export default {
		name: "PcWebLogin",
		components: {
			FooterVueTwo
		},
		data() {
			return {
				// metaInfo
				title: this.$t('登录'),
				email: '',
				pwd: '',
				IsRemenber: false,
			}
		},
		metaInfo() {
			return {
				title: this.title, // set a title
			};
		},
		mounted: function() {
			this.IsRemenber = sessionStorage.getItem("IsRemenber");
			if(this.IsRemenber){
				this.email = sessionStorage.getItem("email");
				this.pwd = sessionStorage.getItem("pwd");
			}
		},
		created(){
			let token = getCookies('web-token');
			if(token){
				this.$router.replace({
					path: '/',
				})
			}
		},
		methods: {
			// 记住密码
			rememberPwd: function(e) {
				this.IsRemenber = !this.IsRemenber;
			},
			// 登录
			async login() {
				let that = this;
				if(!that.email || !that.pwd) return that.$Message.error(that.$t('请输入账号密码'))
				let param = {
					email: that.email,
					pwd: that.pwd,
					domain_url: window.location.origin
				}
				submitLogin(param).then((res)=>{
					let data = res.data;
                    let expires = that.getExpiresTime(data.expires_time);
                    setCookies("uid", data.uid, expires);
                    setCookies("web-token", data.token, expires);
                    setCookies("web-expires_time", data.expires_time, expires);
					if (that.IsRemenber) {
						sessionStorage.setItem("IsRemenber", this.IsRemenber);
						sessionStorage.setItem("email", this.email.trim());
						sessionStorage.setItem("pwd", this.pwd.trim());
					} else {
						sessionStorage.clear("IsRemenber");
						sessionStorage.clear("email");
						sessionStorage.clear("pwd");
					}
					that.$Message.success(res.msg);
					location.reload();
				}).catch((err)=>{
					that.$Message.error(err.msg);
				})
			},
			getExpiresTime(expiresTime) {
                let nowTimeNum = Math.round(new Date() / 1000);
                let expiresTimeNum = expiresTime - nowTimeNum;
                return parseFloat(parseFloat(parseFloat(expiresTimeNum / 60) / 60) / 24);
            },
		}
	}
</script>


<style>
	body {
		background-image: url(../../assets/image/hangda/logo/logo_bg.png) !important;
		background-size: 100% 100% !important;
		background-repeat: no-repeat !important;
	}
</style>
<style scoped>
	a {
		text-decoration: unset;
		color: unset;
	}

	a:hover {
		color: unset;
	}

	a:visited {
		color: unset;
	}

	a:active {
		color: unset;
	}

	input:focus {
		outline: none;
	}

	.index {
		width: 100%;
		height: 100vh;

	}

	.login-box {
		width: 473px;
		/* border: 1px solid #f00; */
	}

	.login-box .li .input-box {
		border-radius: 7px;
		margin-top: 11px;
	}
	.login-box .li .input-box:hover {
		outline:1px solid rgba(66, 132, 220, 0.7);
	}
	.login-box .li .input-box input {
		widows: 100%;
		height: 100%;
		border: none;
	}

	.login-box .remember-password {
		color: #333;
	}

	.login-box .login-button {
		width: 100%;
		border-radius: 12px;
		background-color: #4284DC;
		border: none;
		height: 60px;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		color: #fff;
		font-size: 20px;
	}
	.login-box .login-button2 {
		width: 100%;
		border-radius: 12px;
		background-color: #dadbdf;
		border: none;
		height: 60px;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		color: #fff;
		font-size: 20px;
	}
	.login-box .login-button:hover {
		background-color: rgba(66, 132, 220, 0.8);
	}


	.img-icon{
		width: 20px;padding-top: 3px;
	}

	.checkbox-ico{
		width: 17px;height: 17px;
	}

@media screen and (max-device-width: 768px) {
	.login-box {
		width: 100%;
		/* border: 1px solid #f00; */
	}

	.img-icon{
		width: 4vw;
		padding: 0;
	}

	.login-box .li .input-box {
		border-radius: 7px;
		margin-top: 1.52207vh;
	}

	.checkbox-ico{
		width: 4vw;height: 4vw;
	}

	.login-box .login-button2{
		height: 5.32725vh;
		font-size: 3.73333vw;
		border-radius: 6px;
		background-color: rgba(66, 132, 220, 1);
	}

	.login-box .login-button{
		font-size: 3.73333vw;
		border-radius: 6px;
		background-color: rgba(66, 132, 220, 1);
	}

	.btn-route{
		color: rgba(66, 132, 220, 1) !important;
		border:1px solid rgba(66, 132, 220, 1);
		border-radius: 6px;
		flex: 1;
		height: 5.32725vh;
	}
}
</style>